import React, { Component } from "react";
export const AppContext = React.createContext();

class AppProvider extends Component {
  state = {};

  render() {
    if (!this.state.loading) {
      return (
        <AppContext.Provider
          value={{
            state: this.state,
            changeTest: () => {
              this.setState({
                test: "globabl state!"
              });
            }
          }}
        >
          {this.props.children}
        </AppContext.Provider>
      );
    } else {
      console.log("loading");
    }
  }
}

export default AppProvider;

// Super simple context for mess:
//
// export the class AppProvider and wrap the app.js render
// like this:
// import AppProvider from 'this location ;)'
//
// export the constant AppContext and import where u need it
// like this:
// import { AppContext } from 'this location ;)'
// then after the closing of the class add this line:
//
// CLASSNAME.contextType = AppContext;
//
// there you go tiger, now u can access the context "value" Object
//
// for example, inside the class {context.state.whatever}
//
// wanna change something?
//
// inside the "value" call a function like this:
//
// functionName: () => {
//   console.log('test');
// }
//
// inside the class u can do <asd onClick={context.functionName} />
