import React, { Component } from "react";
import "../styles/header.css";
import HeaderTabNav from "../components/headerTabNav";
import HeaderDrawerNav from "../components/headerDrawerNav";
import HeaderContact from "../components/headerContact";
import { AppContext } from "../AppContext";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import Logo from "../logo.svg";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="header">
        <AppBar className="appbarheader" position="fixed" color="primary">
          <div className="takeaway" style={{ display: "none" }}>
            Abbiamo attuato ed introdotto un servizio di take away! scopri il nostro <a href="/menus/il-take-away">MENU</a>
          </div>
          <Toolbar>
            <IconButton variant="contained" className="opendrawer" onClick={this.toggleDrawer("left", true)} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Link className={classes.grow} to={this.props.locale ? `/${this.props.locale}` : `/`} title="Grotto Figini">
              <img className="logo" src={Logo} alt="Grotto Figini" />
            </Link>

            <HeaderTabNav locale={this.props.locale} url={this.props.url} />
            <HeaderContact locale={this.props.locale} url={this.props.url} />
          </Toolbar>

          <div>
            <Drawer className="drawer" open={this.state.left} onClose={this.toggleDrawer("left", false)}>
              <div className="drawerlist" tabIndex={0} role="button" onClick={this.toggleDrawer("left", false)} onKeyDown={this.toggleDrawer("left", false)}>
                <ListItem component={Link} to={this.props.locale ? `/${this.props.locale}` : `/`} button key="home">
                  <ListItemText primary="Home" />
                </ListItem>

                <HeaderDrawerNav locale={this.props.locale} url={this.props.url} />
              </div>
            </Drawer>
          </div>
        </AppBar>
      </div>
    );
  }
}
Header.contextType = AppContext;

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
