import React, { Component } from "react";

import { GetContactInfo } from "../helpers";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";

class HeaderContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      anchorEl: null
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const data = await GetContactInfo(this.props.locale);
    this.setState({
      data: data,
      anchorEl: null,
      loading: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.handleData();
    }
  }

  async componentDidMount() {
    this.handleData();
  }

  render() {
    if (!this.state.loading) {
      return (
        <>
          <strong>{this.state.data.title.toUpperCase()}</strong>
          <br />
          <br />
          <table>
            <tbody>
              <tr>
                <td>
                  <LocationOnIcon />
                </td>
                <td>{this.state.data.name}</td>
              </tr>
              <tr>
                <td />
                <td>{this.state.data.street}</td>
              </tr>
              <tr>
                <td />
                <td>
                  {this.state.data.cap} {this.state.data.city}
                </td>
              </tr>
              <tr>
                <td>
                  <PhoneIcon />
                </td>
                <td>
                  <a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a>
                </td>
              </tr>
              <tr>
                <td>
                  <EmailIcon />
                </td>
                <td>
                  <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    } else {
      return null;
    }
  }
}

export default HeaderContact;
