import React, { Component } from "react";

import "./App.css";
import { Helmet } from "react-helmet";

import { Route, Switch } from "react-router";
import ReactGA from "react-ga";
import Home from "./containers/home";
import Page from "./containers/page";
import Menu from "./containers/menu";
import Contact from "./containers/contact";
import Bottom from "./containers/bottom";
import { GetGoogleSettings } from "./helpers";
import AppProvider from "./AppContext";

import ClearIcon from "@material-ui/icons/Clear";

import Modal from "@material-ui/core/Modal";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  async componentDidMount() {
    const cachedData = sessionStorage.getItem("covid_info");
    // if (!cachedData) {
    //   console.log(true);
    //   this.setState({
    //     showModal: true
    //   });
    //   sessionStorage.setItem("covid_info", true);
    // }

    this.handleClose = () => {
      this.setState({
        showModal: false
      });
    };

    const googleSettings = await GetGoogleSettings();
    ReactGA.initialize(googleSettings.google_id);

    this.closePopup = () => {
      this.setState({
        showModal: false
      });
      sessionStorage.setItem("covid_info", true);
    };
  }

  render() {
    return (
      <AppProvider>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Grotto Figini</title>
          </Helmet>

          <Switch>
            <Route path="/" exact component={Home} />

            <Route path="/contact" component={Contact} />
            <Route path="/menus/:id" component={Menu} />
            <Route path="/page/:id" component={Page} />

            <Route path="/:locale" exact component={Home} />

            <Route path="/:locale/contact" exact component={Contact} />
            <Route path="/:locale/menus/:id" component={Menu} />
            <Route path="/:locale/page/:id" component={Page} />
          </Switch>
          <Bottom />
          {this.state.showModal ? (
            <div className="popup">
              <div className="pupupInner">
                <div
                  className="close"
                  onClick={() => {
                    this.closePopup();
                  }}
                >
                  <ClearIcon />
                </div>
                <h2 style={{ margin: "1rem 0" }}>Covid info</h2>
                <p>
                  Apertura terrazza e servizio take away 02.05.2021, solo con il sole.
                  <br />
                  <br />
                  Riservazione consigliata allo:
                  <br />
                  091 994 40 80
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </AppProvider>
    );
  }
}

export default App;
