import React, { Component } from "react";

import { GetHeaderPageData } from "../helpers";
import { GetHeaderMenuData } from "../helpers";
import { GetContactInfo } from "../helpers";

import Button from "@material-ui/core/Button";
import SelectLang from "./selectLang";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";

class HeaderTabNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataNav: [],
      dataMenu: [],
      dataContact: [],
      loading: true,
      anchorEl: null
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const dataNav = await GetHeaderPageData(this.props.locale);
    const dataMenu = await GetHeaderMenuData(this.props.locale);
    const dataContact = await GetContactInfo(this.props.locale);
    this.setState({
      dataNav: dataNav.entries,
      dataMenu: dataMenu,
      dataContact: dataContact,
      loading: false,
      anchorEl: null
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.handleData();
    }
  }

  async componentDidMount() {
    this.handleData();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    if (!this.state.loading) {
      const { anchorEl } = this.state;
      return (
        <div className="tabnav">
          {this.state.dataNav.map((field, index) => {
            return (
              <Button
                className="tabnavitem"
                component={Link}
                to={this.props.locale ? `/${this.props.locale}/page/${field.title_slug}` : `/page/${field.title_slug}`}
                key={field.title}
              >
                {field.title}
              </Button>
            );
          })}

          <Button className="tabnavitem" aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} aria-label="Menus">
            Menu
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
            {this.state.dataMenu.entries.map((field, index) => {
              return (
                <MenuItem
                  onClick={this.handleClose}
                  component={Link}
                  to={this.props.locale ? `/${this.props.locale}/menus/${field.title_slug}` : `/menus/${field.title_slug}`}
                  key={field.title}
                >
                  {field.title}
                </MenuItem>
              );
            })}
          </Menu>
          <Button
            className="tabnavitem"
            component={Link}
            to={this.props.locale ? `/${this.props.locale}/contact` : `/contact`}
            key={this.state.dataContact.title}
          >
            {this.state.dataContact.title}
          </Button>
          <SelectLang locale={this.props.locale} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default HeaderTabNav;
