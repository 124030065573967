import React, { Component } from "react";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import "../styles/antimess.css";
import "../styles/homepageSection.css";
import Logo from "../altlogo.svg";
import Glifo from "../glifo.svg";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

class HomepageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div to="lul" className="homepagesection">
        <Parallax bgImage={`http://admin.grottofigini.ch${this.props.data.hero_image.path}`} strength={500}>
          <div className="parallaxcontainer" style={{ height: "50vh" }}>
            <div className="innerparallax">
              <Fade>
                <div>
                  {this.props.pos === 0 ? <img src={Logo} alt="Grotto Figini" /> : null}
                  <span className="herotitle">{this.props.data.hero_title}</span>
                </div>
              </Fade>
            </div>
            {this.props.pos === 0 ? <KeyboardArrowDownIcon className="scrolldown" /> : null}
          </div>
        </Parallax>
        <Parallax className="parallaxcontent" bgImage={Glifo} strength={500}>
          <div className="thegrid padding content">
            <Fade left>
              <div className="thecell x50 xs-x100">
                <img src={`http://admin.grottofigini.ch${this.props.data.excerpt_image.path}`} alt={this.props.data.title} />
              </div>
            </Fade>
            <Fade right>
              <div className="thecell x50 xs-x100">
                <h2>{this.props.data.title}</h2>
                <p className="excerpt">{this.props.data.excerpt}</p>
                <Link
                  to={this.props.locale ? `/${this.props.locale}/page/${this.props.data.title_slug}` : `/page/${this.props.data.title_slug}`}
                  className="more"
                >
                  {this.props.locale ? "Visit" : "Scopri"} {this.props.data.title}
                </Link>
              </div>
            </Fade>
          </div>
        </Parallax>
      </div>
    );
  }
}

export default HomepageSection;
