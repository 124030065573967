import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../layouts/layout";
import { GetHomeData } from "../helpers";

// import { GetFacebookSettings } from "../helpers";
import { Helmet } from "react-helmet";
import HomepageSection from "../components/homepageSection";
import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });

    const data = await GetHomeData(this.props.match.params.locale);

    // const googleSettings = await GetGoogleSettings();
    // ReactGA.initialize(googleSettings.google_id);

    // const facebookSettings = await GetFacebookSettings();
    // const fboptions = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: false // enable logs
    // };
    // ReactPixel.init(facebookSettings.facebook_id, null, fboptions);
    // ReactPixel.pageView();

    this.setState({
      data: data.entries,
      loading: false
    });
    window.scroll(0, 0);
  }

  async componentDidMount() {
    this.handleData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.locale !== prevProps.match.params.locale) {
      this.handleData();
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <Layout page="home" locale={this.props.match.params.locale} url={this.props.match.url}>
        {!this.state.loading ? (
          <div className="home">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>Home - Grotto Figini</title>
              <meta name="description" content />
            </Helmet>

            {this.state.data.map((data, i) => {
              return <HomepageSection data={data} pos={i} key={i} locale={this.props.match.params.locale} />;
            })}
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </Layout>
    );
  }
}

export default Home;
