import React, { Component } from "react";

import { GetContactInfo } from "../helpers";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class HeaderContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      anchorEl: null
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const data = await GetContactInfo(this.props.locale);
    this.setState({
      data: data,
      anchorEl: null,
      loading: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.handleData();
    }
  }

  async componentDidMount() {
    this.handleData();
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    if (!this.state.loading) {
      const { anchorEl } = this.state;
      return (
        <span className="selectlang">
          <IconButton aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} aria-label="Contact">
            <PhoneIcon />
          </IconButton>
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
            <MenuItem onClick={this.handleClose} component="a" href={`tel:${this.state.data.phone}`} title="Phone">
              <PhoneIcon /> {this.state.data.phone}
            </MenuItem>
            <MenuItem onClick={this.handleClose} component="a" href={`mailto:${this.state.data.email}`} title="Email">
              <EmailIcon /> {this.state.data.email}
            </MenuItem>
          </Menu>
        </span>
      );
    } else {
      return null;
    }
  }
}

export default HeaderContact;
