export async function GetHomeData(locale) {
  const cachedData = sessionStorage.getItem(`homeData${locale}`);

  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/collections/get/pages", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale,
      fields: {
        title: 1,
        title_en: 1,
        title_slug: 1,
        hero_title: 1,
        hero_title_en: 1,
        hero_image: 1,
        excerpt: 1,
        excerpt_en: 1,
        excerpt_image: 1
      }
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`homeData${locale}`, JSON.stringify(data));
  return data;
}

export async function GetGoogleSettings() {
  const cachedData = sessionStorage.getItem(`googleSettings`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/singletons/get/google", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    }
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();

  sessionStorage.setItem(`googleSettings`, JSON.stringify(data));
  return data;
}

export async function GetFacebookSettings() {
  const cachedData = sessionStorage.getItem(`facebookSettings`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/singletons/get/facebook", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    }
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();

  sessionStorage.setItem(`facebookSettings`, JSON.stringify(data));
  return data;
}

export async function GetSocialSettings(locale) {
  const cachedData = sessionStorage.getItem(`socialSettings${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/singletons/get/social", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`socialSettings${locale}`, JSON.stringify(data));
  return data;
}

export async function GetPageData(locale, slug) {
  const cachedData = sessionStorage.getItem(`${slug}${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }
  const response = await fetch("http://admin.grottofigini.ch/api/collections/get/pages", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale,
      filter: { title_slug: slug }
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`${slug}${locale}`, JSON.stringify(data));

  return data;
}

export async function GetHeaderPageData(locale) {
  const cachedData = sessionStorage.getItem(`headerPageData${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/collections/get/pages", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale,
      fields: {
        title: 1,
        title_en: 1,
        title_slug: 1
      }
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`headerPageData${locale}`, JSON.stringify(data));
  return data;
}

export async function GetHeaderMenuData(locale) {
  const cachedData = sessionStorage.getItem(`headerMenuData${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/collections/get/menus", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale,
      fields: {
        title: 1,
        title_en: 1,
        title_slug: 1
      }
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`headerMenuData${locale}`, JSON.stringify(data));
  return data;
}

export async function GetMenuData(locale, slug) {
  const cachedData = sessionStorage.getItem(`menu${slug}${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }
  const response = await fetch("http://admin.grottofigini.ch/api/collections/get/menus", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale,
      filter: { title_slug: slug }
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`menu${slug}${locale}`, JSON.stringify(data));

  return data;
}

export async function GetContactInfo(locale) {
  const cachedData = sessionStorage.getItem(`contactInfo${locale}`);
  if (cachedData) {
    const data = JSON.parse(cachedData);
    return data;
  }

  const response = await fetch("http://admin.grottofigini.ch/api/singletons/get/contactinfo", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Cockpit-Token": "16200b69ece7565eab47e3fe106237"
    },
    body: JSON.stringify({
      lang: locale
    })
  });

  if (!response.ok) {
    return;
  }
  const data = await response.json();
  sessionStorage.setItem(`contactInfo${locale}`, JSON.stringify(data));
  return data;
}
