import React, { Component } from "react";
import "../styles/map.css";
import { Map, TileLayer, Marker } from "react-leaflet";

type State = {
  lat: number,
  lng: number,
  zoom: number
};

export default class SimpleExample extends Component<{}, State> {
  state = {
    lat: 45.9937363,
    lng: 8.9308951,
    zoom: 16
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    return (
      <Map center={position} zoom={this.state.zoom} scrollWheelZoom={false}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} />
      </Map>
    );
  }
}
