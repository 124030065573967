import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../layouts/layout";
import { GetMenuData } from "../helpers";
// import { GetGoogleSettings } from "../helpers";
// import { GetFacebookSettings } from "../helpers";
import { Helmet } from "react-helmet";
import { Parallax } from "react-parallax";
import Fade from "react-reveal/Fade";
import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";

import "../styles/page.css";
import "../styles/antimess.css";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const data = await GetMenuData(this.props.match.params.locale, this.props.match.params.id);

    // const googleSettings = await GetGoogleSettings();
    // ReactGA.initialize(googleSettings.google_id);

    // const facebookSettings = await GetFacebookSettings();
    // const fboptions = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: false // enable logs
    // };
    // ReactPixel.init(facebookSettings.facebook_id, null, fboptions);
    // ReactPixel.pageView();

    this.setState({
      data: data.entries,
      loading: false
    });
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.handleData();

    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.handleData();
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <Layout page="menu" locale={this.props.match.params.locale} url={this.props.match.url}>
        {!this.state.loading ? (
          <div className="page">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>{this.state.data[0].title} - Grotto Figini</title>
              <meta name="description" content={this.state.data[0].meta_description} />
            </Helmet>
            <Parallax bgImage={`http://admin.grottofigini.ch${this.state.data[0].hero_image.path}`} strength={500}>
              <div className="parallaxcontainer" style={{ height: "50vh" }}>
                <div className="innerparallax">
                  <div>
                    <span className="herotitle">{this.state.data[0].title}</span>
                  </div>
                </div>
              </div>
            </Parallax>
            <div className="sections">
              <div className="section thegrid">
                <Fade>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<p>${this.state.data[0].content}</p>`
                    }}
                  />
                </Fade>
              </div>
            </div>
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </Layout>
    );
  }
}

export default Page;
