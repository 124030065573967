import React, { Component } from "react";
import "../styles/footer.css";
import { GetContactInfo } from "../helpers";
import FooterSocial from "../components/footerSocial";
import FooterContact from "../components/footerContact";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataContact: []
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const dataContact = await GetContactInfo(this.props.locale);
    this.setState({
      dataContact: dataContact,
      loading: false
    });
  }

  async componentDidMount() {
    this.handleData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.handleData();
    }
  }

  render() {
    if (!this.state.loading) {
      return (
        <div className="footer thegrid padding">
          <div className="thecell x33 s-x50 xs-x100 padding">
            <FooterContact locale={this.props.locale} />
          </div>
          <div className="thecell x33 s-x50 xs-x100 padding">
            <div
              dangerouslySetInnerHTML={{
                __html: `<span>${this.state.dataContact.timetable}</span>`
              }}
            />
          </div>
          <div className="thecell x33 s-x100 padding socialicons">
            <FooterSocial locale={this.props.locale} />
            <br />
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Footer;
