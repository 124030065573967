import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout from "../layouts/layout";

import { GetContactInfo } from "../helpers";
// import { GetGoogleSettings } from "../helpers";
// import { GetFacebookSettings } from "../helpers";
import { Helmet } from "react-helmet";

import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Fade from "react-reveal/Fade";
import ReactGA from "react-ga";
// import ReactPixel from "react-facebook-pixel";
import Map from "../components/map";
import "../styles/page.css";
import "../styles/contact.css";
import "../styles/antimess.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: []
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const data = await GetContactInfo(this.props.match.params.locale);

    // const googleSettings = await GetGoogleSettings();
    // ReactGA.initialize(googleSettings.google_id);

    // const facebookSettings = await GetFacebookSettings();
    // const fboptions = {
    //   autoConfig: true, // set pixel's autoConfig
    //   debug: false // enable logs
    // };
    // ReactPixel.init(facebookSettings.facebook_id, null, fboptions);
    // ReactPixel.pageView();

    this.setState({
      data: data,
      loading: false
    });
    window.scroll(0, 0);
  }

  componentDidMount() {
    this.handleData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.locale !== prevProps.match.locale) {
      this.handleData();
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  render() {
    return (
      <Layout page="contact" locale={this.props.match.params.locale} url={this.props.match.url}>
        {!this.state.loading ? (
          <div className="page contact">
            <Helmet>
              <html lang={this.props.match.params.locale ? this.props.match.params.locale : "it"} />
              <meta charSet="utf-8" />
              <title>{this.state.data.title} - Grotto Figini</title>
              <meta name="description" content={this.state.data.meta_description} />
            </Helmet>
            <div className="sections">
              <div className="section ">
                <Fade>
                  <h2>{this.state.data.title}</h2>

                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <LocationOnIcon />
                        </td>
                        <td>{this.state.data.name}</td>
                      </tr>
                      <tr>
                        <td />
                        <td>{this.state.data.street}</td>
                      </tr>
                      <tr>
                        <td />
                        <td>
                          {this.state.data.cap} {this.state.data.city}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <PhoneIcon />
                        </td>
                        <td>
                          <a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <EmailIcon />
                        </td>
                        <td>
                          <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Fade>
              </div>

              <Map />
            </div>
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </Layout>
    );
  }
}

export default Contact;
