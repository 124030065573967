import React, { Component } from "react";
import { GetHeaderPageData } from "../helpers";
import { GetContactInfo } from "../helpers";
import { GetHeaderMenuData } from "../helpers";
import LanguageIcon from "@material-ui/icons/Language";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";

class HeaderDrawerNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataNav: [],
      dataMenu: [],
      dataContact: [],
      loading: true
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const dataNav = await GetHeaderPageData(this.props.locale);
    const dataMenu = await GetHeaderMenuData(this.props.locale);
    const dataContact = await GetContactInfo(this.props.locale);
    this.setState({
      dataNav: dataNav.entries,
      dataMenu: dataMenu.entries,
      dataContact: dataContact,
      loading: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.handleData();
    }
  }

  async componentDidMount() {
    this.handleData();
  }

  render() {
    if (!this.state.loading) {
      return (
        <div>
          {this.state.dataNav.map((field, index) => {
            return (
              <ListItem
                component={Link}
                to={this.props.locale ? `/${this.props.locale}/page/${field.title_slug}` : `/page/${field.title_slug}`}
                button
                key={field.title}
              >
                <ListItemText primary={field.title} />
              </ListItem>
            );
          })}
          <br />
          <ListItem className="listitemlabel" key="menu">
            <ListItemText primary="Menu" />
          </ListItem>
          {this.state.dataMenu.map((field, index) => {
            return (
              <ListItem
                component={Link}
                to={this.props.locale ? `/${this.props.locale}/menus/${field.title_slug}` : `/menus/${field.title_slug}`}
                button
                key={field.title}
              >
                <ListItemText primary={field.title} />
              </ListItem>
            );
          })}
          <br />
          <ListItem component={Link} to={this.props.locale ? `/${this.props.locale}/contact` : `/contact`} button key={this.state.dataContact.title}>
            <ListItemText primary={this.state.dataContact.title} />
          </ListItem>
          <br />
          <ListItem key="language" className="listitemlabel">
            <LanguageIcon style={{ color: "#fff" }} />
          </ListItem>
          <ListItem component={Link} to="/" button key="IT">
            <ListItemText primary="IT" />
          </ListItem>
          <ListItem component={Link} to="/en" button key="EN">
            <ListItemText primary="EN" />
          </ListItem>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default HeaderDrawerNav;
