import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import LanguageIcon from "@material-ui/icons/Language";
import "../styles/selectLang.css";
import { Link } from "react-router-dom";

class SelectLang extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    if (!this.state.loading) {
      const { anchorEl } = this.state;
      return (
        <span className="selectlang">
          <IconButton aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} aria-label="Lang">
            <LanguageIcon />
          </IconButton>
          &nbsp;
          {this.props.locale ? this.props.locale.toUpperCase() : "IT"}
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
            <MenuItem onClick={this.handleClose} component={Link} to="/" title="Italiano">
              IT
            </MenuItem>
            <MenuItem onClick={this.handleClose} component={Link} to="/en" title="English">
              EN
            </MenuItem>
          </Menu>
        </span>
      );
    }

    return <div className="loading" />;
  }
}

export default SelectLang;
