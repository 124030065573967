import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Bottom extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="bottom">
        <Helmet>
          <script type="text/javascript">
            {`
            (function(w, d) {
              var loader = function() {
                var s = d.createElement("script"),
                  tag = d.getElementsByTagName("script")[0];
                s.src = "https://cdn.iubenda.com/iubenda.js";
                tag.parentNode.insertBefore(s, tag);
              };
              if (w.addEventListener) {
                w.addEventListener("load", loader, false);
              } else if (w.attachEvent) {
                w.attachEvent("onload", loader);
              } else {
                w.onload = loader;
              }
            })(window, document);
            `}
          </script>
        </Helmet>
        <div className="inner">
          <a href="https://www.iubenda.com/privacy-policy/74817742" className="iubenda-white iubenda-embed" title="Privacy Policy ">
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

export default Bottom;
