import React, { Component } from "react";
import { GetSocialSettings } from "../helpers";
import { SocialIcon } from "react-social-icons";
import "../styles/footerSocial.css";

class FooterSocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  async handleData() {
    this.setState({
      loading: true
    });
    const data = await GetSocialSettings(this.props.locale);
    this.setState({
      data: data,
      loading: false
    });
  }

  async componentDidMount() {
    this.handleData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.locale !== prevProps.locale) {
      this.handleData();
    }
  }

  render() {
    if (!this.state.loading) {
      if (this.state.data.facebook || this.state.data.twitter || this.state.data.instagram || this.state.data.pinterest) {
        return (
          <div className="footersocial">
            <strong>{this.state.data.title}</strong>
            <br />
            <br />
            {this.state.data.facebook ? (
              <div>
                <SocialIcon url={this.state.data.facebook} network="facebook" bgColor="#ffffff" fgColor="#81303c" />
              </div>
            ) : null}
            {this.state.data.twitter ? (
              <div>
                <SocialIcon url={this.state.data.twitter} network="twitter" bgColor="#ffffff" fgColor="#81303c" />
              </div>
            ) : null}
            {this.state.data.instagram ? (
              <div>
                <SocialIcon url={this.state.data.instagram} network="instagram" bgColor="#ffffff" fgColor="#81303c" />
              </div>
            ) : null}
            {this.state.data.pinterest ? (
              <div>
                <SocialIcon url={this.state.data.pinterest} network="pinterest" bgColor="#ffffff" fgColor="#81303c" />
              </div>
            ) : null}
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

export default FooterSocial;
